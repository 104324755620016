import EventEmitter from "./EventEmitter";

export default class LoadingManager extends EventEmitter{
    constructor(){
        super()
        this.loadAmount = 6
        this.currentAmount = 0
        this.WeatherBoolean = false


        this.verifier = async () =>{
            this.currentAmount++
            if(this.currentAmount === this.loadAmount && this.WeatherBoolean){
                this.trigger('done')
            }
        }
        
        this.setWeatherBoolean = (bool) =>{
            this.WeatherBoolean = bool
            this.verifier()
        }
      
        this.done = () => {
            this.verifier()
        }
    }
}