import { Environment,PresentationControls, ContactShadows,Float} from '@react-three/drei'
import Avatar from './Avatar.jsx'
import StreetSign from './Streetsign.jsx'
import Nemo from './Nemo.jsx'
import Bag from './Bag.jsx'
import Weather from './Weather.jsx'

export default function World()
{     
    return <>
        <PresentationControls
            global
            rotation={[0,0,0]}
            polar={[0.0,0]}
            config={{mass:2,tension: 400}}
            azimuth={[-0.8,0.8]}
            snap={{mass: 2, tension:400}}

        >   
            <StreetSign />
            <Float speed={0.5}>
                <Weather/>
            </Float>
            <Nemo />
            <Bag/>
            <Avatar />
            <Environment preset='dawn' blur={0.8} />
            <hemisphereLight groundColor="red" />
            
        </PresentationControls>
        <ContactShadows
            position={[0,-0.01,0]}
            opacity={ .5 }
            scale={8}
            blur={ 1 }
        />
    </>
}