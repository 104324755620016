import { track } from '@vercel/analytics';
import * as THREE from 'three'
import { useAnimations } from "@react-three/drei"
import { useLoader } from "@react-three/fiber"
import { useRef, useEffect,useMemo,useState } from "react"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import AppFunctions from "../AppFunctions.js"

const app = new AppFunctions()

const Model = ({modelDetails}) =>{
    const modelGLTF = useLoader (GLTFLoader, modelDetails.url, () =>{app.loadingManager.done()})

    const model ={
        children: modelGLTF.scene.children[0].children,
        isShort: modelDetails.isShort
    }
    //set animations
    let {actions, mixer, clips} = useAnimations(modelGLTF.animations, modelGLTF.scene)
    let animationNumber = useRef(0)
    let animationLib = {}
    let animationArray = []
    const setupAnimationData = () =>{

        animationArray.push(
            actions.first,
            actions.second,
            actions.third,
            actions.fourth,
            actions.fifth,
            actions.sixth,
            // actions.seventh,
            )    
        animationNumber.current = 0
    }
        
    //Since it's a useRef, even on a rerender it keeps the number
    let currentAction = animationNumber.current
    
    animationLib.play = (number) =>{
        
        const newAction = animationArray[number]
        const oldAction = currentAction    
        
        newAction.reset()
        newAction.play()
        newAction.crossFadeFrom(oldAction, 1)
    
        currentAction = newAction
    }
    
    const playNextAnimation = () =>{
        if(animationNumber.current === animationArray.length-1){
            animationNumber.current = 1
            animationLib.play(animationNumber.current)
        }else{
            animationNumber.current++
            animationLib.play(animationNumber.current)
        }
    }
    
    // play animations after al is loaded
    useEffect(() =>{        
        //Setup materials for 3D model
        app.materials.setMaterial(model.children, model.isShort)
        //Setup Event listerner one time
        mixer.addEventListener('finished', playNextAnimation)
        mixer.addEventListener('loop', playNextAnimation)
        
        //Setup Animation one time
        setupAnimationData()
        currentAction = animationArray[animationNumber.current]
        currentAction.loop = THREE.LoopOnce
        currentAction.clampWhenFinished = true

        currentAction.play()
    
    },[])

    return <>
        <primitive object={modelGLTF.scene}/>
    </>
}

function Avatar(){
    
    const [modelInfo, setModelInfo] = useState(null)
    useEffect(() =>{
        const loadWeatherModel = async() =>{
            const weather = await app.weather.getWeatherData()            
            const modelPath = weather.model.path+'_'+weather.model.avatar            
            const model = {
                url:app.imports.models[modelPath],
                isShort: weather.model.isShort
            }
            setModelInfo(model)
        }
        loadWeatherModel()

    },[])
    if(!modelInfo){
        return null
    }
    return <>
        <Model modelDetails={modelInfo}/>
    </>
}
export default Avatar