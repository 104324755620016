import * as THREE from 'three'
import ColorWays from './Colorways.js'
import NewColorways from './NewColorways.js'
import DefaultColors from './DefaultColors.js'
import AppFunctions from '../AppFunctions.js'

export default class MaterialCreator{
    constructor(){

    this.app = new AppFunctions()
    
    this.colorways = ColorWays
    this.defaultColors = DefaultColors
    this.app.colorcode.requestCode()

    this.newColorWays = NewColorways
    this.currentColorCode = null
    this.currentMaterial = null    
    
    }
    async createMaterial(isShortBoolean){        
        if (this.currentColorCode == null){
            this.currentColorCode = await this.app.colorcode.requestCode()            
            
            let getLengthType = (isShort) => {
                return isShort ? 'short' : 'long';
            }

            this.currentMaterial = this.newColorWays[this.currentColorCode].parts[getLengthType(isShortBoolean)]            

            this.currentMaterial.background = this.newColorWays[this.currentColorCode].background

            const defaultMaterialColors = this.defaultColors[0].parts

            for(let parts of defaultMaterialColors){
                this.currentMaterial.push(parts)
            }
            return this.currentMaterial    
        }else{
            return this.currentMaterial
        }

    }
    async setMaterial(model){        
        let modelType = await app.weather.getWeatherData()
        const isShort = modelType?.model?.isShort ?? false;  // Use false as a fallback
        const currentMaterial = await this.createMaterial(isShort)
        
        for(let children of model){            
            for(let colorPart of currentMaterial){                
                const child = children.name.toLowerCase()
                if(child === colorPart.name || child.includes(colorPart.name)){
                    children.material = new THREE.MeshStandardMaterial({color: colorPart.color})
                }
            }
        }
    }
}