import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import { Analytics } from '@vercel/analytics/react';


const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(<>
        <App />
        <Analytics />
    </>
)
