import MaterialCreator from "./Materials/MaterialCreator.js"
import sources from "./sources.js"
import GenerateColorCode from "./Utils/GenerateColorCode.js"
import Imports from "./Utils/Imports.js"
import ISeeYou from "./Utils/ISeeYou.js"
import Weather from "./Utils/Weather.js"
import Environment from './Utils/Environment.js'
import LoadingManager from "./Utils/LoadingManager.js"

let instance = null

export default class AppFunctions{
    constructor(){
        
        if (instance){
            return instance
        }
        instance = this
        window.app = this

        //setup
        this.environment = new Environment()
        this.colorcode = new GenerateColorCode()
        this.imports = new Imports(sources)
        this.weather = new Weather()
        this.materials = new MaterialCreator() 
        this.iSeeYou = new ISeeYou()       
        this.loadingManager = new LoadingManager()
    }
}