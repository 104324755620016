import { track } from '@vercel/analytics';
import Image from './Image.jsx';
import {useContext, useEffect, useState } from 'react';
import './ImageCarousel.css'
import { projectContext } from '../App.jsx';


export default function ImageCarousel(props){

    let[sideProjectID, setSideProjectID] = useContext(projectContext)

    let isOverlayOpen = props.trigger
    
    const [imageIndex, setImageIndex] = useState(0)
    const [imageAnimationState, setImageAnimationState] = useState(false)
    const projectImages = props.images

    useEffect(() => {
            setImageAnimationState(false)
            setImageIndex(0)
    },[sideProjectID])
    
    const setDottImage = (index) =>{
        track('DottsImage')
        setImageIndex(index)
    }

    const showNextImage = (method) =>{
        track('NextImage',{image:imageIndex,method:method})
        setImageAnimationState(true)
        setImageIndex((index) =>{   
            if(index === projectImages.length - 1){ 
                return 0
            }else {
                return index +1
            }
        })
    }
    const showPrevImage = (method) =>{
        track('PrevImage',{image:imageIndex,method:method})
        setImageAnimationState(true)
        setImageIndex((index) =>{
            if(index === 0) {
                return projectImages.length -1
            }else {
               return index -1
            }
        })
    }

    useEffect(() =>{
        if(isOverlayOpen){
         const leftKeyPressHandler = (event) =>{
             switch(event.key) {
                case "ArrowRight":
                    showNextImage('ArrowRight')
                    break
                case "ArrowLeft":
                    showPrevImage('ArrowLeft')
                    break
             }
         }

         window.addEventListener('keyup', leftKeyPressHandler)
         return () => {
            window.removeEventListener('keyup',leftKeyPressHandler)
         }
        } 
    },[isOverlayOpen,sideProjectID])

    let sideButtons = () =>{
        if(projectImages.length > 1){
            return <>
            <button style={{position:"absolute", zIndex:"1", left:"0"}} className='caroButton' onClick={() => showPrevImage("mouseClick")}>
                <div className='circleButton'><img src='./icons/arrow_left.svg'/></div>
            </button>
            <button style={{position:"absolute", zIndex:"1", right:"0"}} className='caroButton' onClick={() =>showNextImage("mouseClick")}>    
            <div className='circleButton'><img src='./icons/arrow_right.svg'/></div>
            </button>
            </>
        }else{ return null }
    }

    return <>
    <div className='imageCarouselContainer'>
        {sideButtons()}
        <div style={{ display:"flex",height:"100% ", overflow:"hidden"}}>
            {projectImages.map((image) =>{
                return(
                    <div key={image.id} style={{backgroundColor:image.background,translate:`${-100*imageIndex}%`}}className={imageAnimationState ? 'image-slider-wrapper' : 'image-slider-wrapper no-ani'}>
                        <Image imageUrl={image.url} key={image.id} />
                    </div>
                )
            })}
        </div>
        <div className='dotts'>
            {projectImages.map((image,index) => {
                    if(projectImages.length > 1){
                    return <button className={imageIndex === index ? 'dottButton' : "dottButton hide"} key={image.id} onClick={() =>{setDottImage(index)}}/>
                }else{
                    return null
                }
            })}
        </div>
    </div>
    </>
}