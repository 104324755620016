import { track } from '@vercel/analytics';
import projectData from '../projectData.json'
import sideProjectData from '../sideProjectData.json'
import EventEmitter from './EventEmitter'


export default class ISeeYou extends EventEmitter{
    constructor(){
        super()

        this.signal = []
        this.projectThreshold = projectData.length + sideProjectData.length
        
    }saveProject(projectObject) {
        
        let sideProjectID = projectObject.sideProjectID
        let mainProjectID = projectObject.mainProjectID
        
        const mainProjectExists =  this.signal.some(project => project.mainProjectID === mainProjectID)
        const sideProjectExists =  this.signal.some(project => project.sideProjectID === sideProjectID)
        
        if (!mainProjectExists && mainProjectID !== null) {
            this.signal.push({mainProjectID: mainProjectID})
        }
        
        if(!sideProjectExists && sideProjectID !== null){
            this.signal.push({sideProjectID: sideProjectID})
        }
        
        if(this.signal.length == this.projectThreshold){
            track('special project')
            this.trigger('special')
        }

      }
      
    seeSignal(projectObject){
        this.saveProject(projectObject)
    }
}