export default class Environment{
    constructor(local){

        const apiProdURL = import.meta.env.VITE_PROD_API_URL
        const apiLocalURL = import.meta.env.VITE_LOCAL_API_URL

        this.environmentURL
        this.env
        
        if(!local){
            this.environmentURL = apiProdURL
            this.env = "Prod"
            
        }else{
            this.environmentURL = apiLocalURL
            this.env = 'Local'
        }
        
        const establishContact = async () =>{
            try{
                const response = await fetch (this.environmentURL + 'contact')
                if(!response.ok){
                    throw new Error('Contact response was not oke')
                }

                const jsonData = await response.json()
                return jsonData

            }catch(error){
                console.error('Contact response not responding')
            }
        }

        const contactCheck = async () =>{
            const check = await establishContact()
            if(check.status){
                console.log(`Initial contact with ${this.env} environment`);
            }
        }

        contactCheck()
        this.getCurrentEnvironment = () =>{
            return this.environmentURL
        }
    }
}