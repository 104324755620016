import {useState } from 'react';
import './Project.css'

export default function Project(project) {

    const [hoverToggle, setHoverToggle] = useState(false)

    let projectInfo = project.project

    const handleMouseEnter = () =>{
        setHoverToggle(true)
    }
    const handleMouseLeave = () =>{
        setHoverToggle(false)
    }
    
    return <>
        <div className="project-wrapper" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <img className={hoverToggle ? 'project-icon hide ' : 'project-icon'} position={"absolute"}width={56} height={56} src={projectInfo.icon[0].image}/>
            <img className={hoverToggle ? 'project-icon show' : 'project-icon hide'} width={56} height={56} src={projectInfo.icon[1].image}/>
            <div className={hoverToggle ? 'project-name' : 'project-name hide'} style={{fontSize:10,paddingTop:"64px", textAlign:"center"}}>
                <div> {projectInfo.name}</div>  
            </div>
        </div>
    </>

}