import { track } from '@vercel/analytics';
import * as THREE from 'three'
import { useLoader } from "@react-three/fiber"
import { useAnimations } from "@react-three/drei"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import { useEffect,useContext } from "react"
import AppFunctions from "../AppFunctions.js"


export default function Nemo(){

    const app = new AppFunctions()

    const model = useLoader(GLTFLoader, app.imports.models.nemo, () =>{
        app.loadingManager.done()
    })
    const animations = useAnimations(model.animations, model.scene) 


    useEffect(() =>{
        const action = animations.actions.Survey
        action.play()
    })

    return <>
        <primitive scale={0.006} rotation={[0,Math.PI/8,0]} position={[0.75,0,0.1]} object={model.scene} />
    </>
}