export default [
    {
        name: 'avatar',
        parts: [
            {
                name: 'body',
                color: '#9F715A'
            },
            {
                name: 'glasses',
                color: 'silver'
            }
        ]
    } 
]