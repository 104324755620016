import { useLoader } from "@react-three/fiber"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import { useEffect, } from "react"

import AppFunctions from "../AppFunctions.js"
export default function StreetSign(){
    const app = new AppFunctions()
    const model = useLoader(GLTFLoader, app.imports.models.streetsign, () =>{
        app.loadingManager.done()
    })
    
    return <primitive object={model.scene} position={[-1,-.05,-0.8]} />
}