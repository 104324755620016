import { useEffect, useRef, useState } from 'react';
import AppFunctions from '../AppFunctions.js'
import { useFrame, useLoader } from '@react-three/fiber';
import { GLTFLoader} from 'three/examples/jsm/Addons.js';
import * as THREE from 'three'

const app = new AppFunctions()

const KeyFrameAnimation = ({ model }) => {
    const [keyframes] = useState([
      { position: [0, 3, -0.5] }, // Start outside the window
      { position: [0, 2.065, 0] },   // Move to the center
    ]);
  
    let currentFrame = useRef(0);
    let progress = useRef(0);
  
    useFrame(() => {
      const { position: startPosition } = keyframes[currentFrame.current];
      const { position: endPosition } = keyframes[(currentFrame.current + 1) % keyframes.length];
  
      // Interpolate between start and end positions
      model.position.lerpVectors(
        new THREE.Vector3(...startPosition),
        new THREE.Vector3(...endPosition),
        progress.current
      );
  
      progress.current += 0.0018; // Adjust this value to control the speed
  
      if (progress.current >= 1) {
        progress.current = 1; // Stay at the last keyframe
      }
    });
  
    return null;
  };

const Model = ({url}) =>{
    const gltf = useLoader(GLTFLoader, url,() =>{
      app.loadingManager.done()
    })    
    const model = useRef()
    return <>
        <group ref={model}>
            <primitive scale={0.5} object={gltf.scene} />
            <KeyFrameAnimation model={gltf.scene} />
        </group>
    </>  
}

const WeatherModel = () =>{
    
    const [modelUrl, setModelUrl] = useState(null)
    useEffect(() =>{
        const loadWeatherModel = async () => {
          try {
            const weather = await app.weather.getWeatherData()
            if(weather.timeOfDay !== 'night'){
              if(weather.formatWeather){
                setModelUrl(app.imports.models[weather.formatWeather])
             }else{
                app.loadingManager.done()
            }
            }else{              
              setModelUrl(app.imports.models.night)
            }
          }catch(error){
            console.log('Error');
          }
        }
        loadWeatherModel()
    } ,[])

    if(!modelUrl){
        return
    }
    return <>
        <Model url={modelUrl}/>
    </>

}

export default WeatherModel