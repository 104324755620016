import AppFunctions from "../AppFunctions"

export default class Weather{
    constructor(){
        const app = new AppFunctions()
        const weatherUrl = app.environment.getCurrentEnvironment() + 'weather'

// Just an example for posting data to the server
        // const postWeatherData = async () =>{
        //     try{
        //         const response = await fetch(weatherUrl,{
        //             method:'POST',
        //             headers:{
        //                 'Content-type': 'application/json'
        //             },
        //             body: JSON.stringify({data: 'this is a test'})
        //         })
        //         if(!response.ok){
        //             throw new Error('Network response was not ok')
        //         }
        //         const jsonData = await response.json()
        //         return jsonData 
        //     }
        //         catch(error){
        //             console.error(`Error from: ${app.environment.getCurrentEnvironment()}`, error)
        //             return null
        //         }
        // }

        const frondEndBackup = {
            weather:{
                timesUpdated: null,
                weather_condition: null,
                weatherCode: 1063,
                formatWeather: null,
                timeOfDay: 'afternoon',
                model: {
                    isShort: false,
                    path: 'cold',
                    avatar:'cloudy_avatar'
                },
                lastApiUpdated: null,
                lastServerUpdate:{
                    time: null,
                    date: null
                    }
            }
        }   
         this.status = {
            status:{
                location:'local',
                type: 'backup',
                isBackup: true
            }
        }

        const timeOutPromise = (ms) =>{
            return new Promise ((resolve,reject) =>{
                setTimeout(() =>{
                    resolve(frondEndBackup)
                },ms)
            })
        }
        
        const fetchWeatherData = async () =>{       
            try{
                const response = await fetch(weatherUrl)
                if(!response.ok){
                        throw new Error('Network response was not ok')
                }
                const jsonData = await response.json()
                return jsonData 
            }
                catch(error){
                    console.error(`Error from: ${app.environment.getCurrentEnvironment()}`, error)  
                    return frondEndBackup
                }
            }
        
        this.cachedWeather = {}
        this.cachedWeather.data = null

        this.formatWeatherData = async () =>{        
            try{
                const weatherData = await Promise.race([
                    fetchWeatherData(), 
                    timeOutPromise(2000)
                ])
                    const returnWeatherData = weatherData.weather
                
                    app.loadingManager.setWeatherBoolean(true)
                    this.cachedWeather.data = returnWeatherData
                    return returnWeatherData
                }
            catch(error){
                    console.warn(error.message);
            }      
        }

        this.cachedWeather = {
            data: null,
            promise: null
        };

        this.getWeatherData = () =>{
            if(this.cachedWeather.data){
                return this.cachedWeather.data
            }
            if(this.cachedWeather.promise){
                return this.cachedWeather.promise
            }

            this.cachedWeather.promise = this.formatWeatherData()
                .then ((data) =>{                    
                    this.cachedWeather.data = data
                    return data
                })
                .finally(() =>{
                    this.cachedWeather.promise = null
                })
            return this.cachedWeather.promise
        }

    }}  