export default [
    {
        name: 'Tif',
        parts:{
            short:[
                {
                    name: 'cap',
                    color: '#04AAAA'
                },
                {
                    name: 'shirt',
                    color: 'white'
                },
                {
                    name: 'pants',
                    color: '#2F2F2F'
                },
                {
                    name: 'toe',
                    color: '#04AAAA'
                },
                {
                    name: 'mudgard',
                    color: 'black'
                },
                {
                    name: 'heelmudgard',
                    color: 'black'
                },
                {
                    name: 'inside',
                    color: 'black'
                },
                {
                    name: 'outsole',
                    color: 'black'
                },
                {
                    name: 'swoosh',
                    color: 'silver'
                },
                {
                    name: 'sides',
                    color: '#04AAAA'
                },
                {
                    name: 'heeltop',
                    color: 'cyan'
                },
                {
                    name: 'heellogo',
                    color: 'black'
                },
                {
                    name: 'thongmid',
                    color: '#04AAAA'
                },
                {
                    name: 'midsole',
                    color:'white'
                },
                {
                    name: 'thongouter',
                    color: 'black'
                },
                {
                    name: 'thonglogo',
                    color: 'black'
                },
                {
                    name: 'laces',
                    color: 'black'
                },
                {
                    name: 'thonginner',
                    color: 'cyan'
                },
                {
                    name: 'ribs',
                    color: 'black'
                },
                {
                    name: 'sock',
                    color: 'white'
                },
                {
                    name: 'sockring',
                    color: '#185E62' 
                },
                {
                    name:'bag',
                    color:'#242424'
                },
                {
                    name:'zakje',
                    color:'#04AAAA'
                },
                {
                    name:'umbrella',
                    color:"orange"
                },
                {
                    name:'bars',
                    color:"#3b3b3b"
                },
                {
                    name:'band',
                    color:"#362000"
                }
            ],
            long:[
                {
                    name: 'cap',
                    color: '#04AAAA'
                },
                {
                    name: 'shirt',
                    color: '#983636'
                },
                {
                    name: 'pants',
                    color: '#2F2F2F'
                },
                {
                    name: 'toe',
                    color: '#04AAAA'
                },
                {
                    name: 'mudgard',
                    color: 'black'
                },
                {
                    name: 'heelmudgard',
                    color: 'black'
                },
                {
                    name: 'inside',
                    color: 'black'
                },
                {
                    name: 'outsole',
                    color: 'black'
                },
                {
                    name: 'swoosh',
                    color: 'silver'
                },
                {
                    name: 'sides',
                    color: '#04AAAA'
                },
                {
                    name: 'heeltop',
                    color: 'cyan'
                },
                {
                    name: 'heellogo',
                    color: 'black'
                },
                {
                    name: 'thongmid',
                    color: '#04AAAA'
                },
                {
                    name: 'midsole',
                    color:'white'
                },
                {
                    name: 'thongouter',
                    color: 'black'
                },
                {
                    name: 'thonglogo',
                    color: 'black'
                },
                {
                    name: 'laces',
                    color: 'black'
                },
                {
                    name: 'thonginner',
                    color: 'cyan'
                },
                {
                    name: 'ribs',
                    color: 'black'
                },
                {
                    name: 'sock',
                    color: 'white'
                },
                {
                    name: 'sockring',   
                    color: '#185E62' 
                },
                {
                    name:'bag',
                    color:'#242424'
                },
                {
                    name:'zakje',
                    color:'#04AAAA'
                },
                {
                    name:'umbrella',
                    color:"orange"
                },
                {
                    name:'bars',
                    color:"#3b3b3b"
                },
                {
                    name:'band',
                    color:"#362000"
                }
            ]
        },
        background:{
            color01:"#FAB89E",
            color02:"#519DF1",
            degrees:20
        }
    },
    {
        name: 'Pidgeon',
        parts:{
            short:[
                {
                    name: 'cap',
                    color: '#C35649'
                },
                {
                    name: 'shirt',
                    color: 'white'
                },
                {
                    name: 'pants',
                    color: '#2F2F2F'
                },
                {
                    name: 'toe',
                    color: '#EEE4DE'
                },
                {
                    name: 'mudgard',
                    color: '#918783'
                },
                {
                    name: 'heelmudgard',
                    color: '#918783'
                },
                {
                    name: 'inside',
                    color: '#C35649'
                },
                {
                    name: 'outsole',
                    color: '#C35649'
                },
                {
                    name: 'swoosh',
                    color: 'white'
                },
                {
                    name: 'sides',
                    color: '#EEE4DE'
                },
                {
                    name: 'heeltop',
                    color: '#EEE4DE'
                },
                {
                    name: 'heellogo',
                    color: 'white'
                },
                {
                    name: 'thongmid',
                    color: '#918783'
                },
                {
                    name: 'midsole',
                    color:'#777570'
                },
                {
                    name: 'thongouter',
                    color: '#777570'
                },
                {
                    name: 'thonglogo',
                    color: 'white'
                },
                {
                    name: 'laces',
                    color: 'white'
                },
                {
                    name: 'thonginner',
                    color: '#918783'
                },
                {
                    name: 'ribs',
                    color: '#918783'
                },
                {
                    name: 'sock',
                    color: 'white'
                },
                {
                    name: 'sockring',
                    color: '#C35649' 
                },
                {
                    name:'bag',
                    color:'grey'
                },
                {
                    name:'zakje',
                    color:'#2F2F2F'
                },
                {
                    name:'umbrella',
                    color:"#C35649"
                },
                {
                    name:'bars',
                    color:"#3b3b3b"
                },
                {
                    name:'band',
                    color:"#362000"
                }
            ],
            long:[
                {
                    name: 'cap',
                    color: '#4f79b0'
                },
                {
                    name: 'shirt',
                    color: '#823830'
                },
                {
                    name: 'pants',
                    color: '#192d47'
                },
                {
                    name: 'toe',
                    color: '#EEE4DE'
                },
                {
                    name: 'mudgard',
                    color: '#918783'
                },
                {
                    name: 'heelmudgard',
                    color: '#918783'
                },
                {
                    name: 'inside',
                    color: '#C35649'
                },
                {
                    name: 'outsole',
                    color: '#C35649'
                },
                {
                    name: 'swoosh',
                    color: 'white'
                },
                {
                    name: 'sides',
                    color: '#EEE4DE'
                },
                {
                    name: 'heeltop',
                    color: '#EEE4DE'
                },
                {
                    name: 'heellogo',
                    color: 'white'
                },
                {
                    name: 'thongmid',
                    color: '#918783'
                },
                {
                    name: 'midsole',
                    color:'#777570'
                },
                {
                    name: 'thongouter',
                    color: '#777570'
                },
                {
                    name: 'thonglogo',
                    color: 'white'
                },
                {
                    name: 'laces',
                    color: 'white'
                },
                {
                    name: 'thonginner',
                    color: '#918783'
                },
                {
                    name: 'ribs',
                    color: '#918783'
                },
                {
                    name: 'sock',
                    color: 'white'
                },
                {
                    name: 'sockring',
                    color: '#C35649' 
                },
                {
                    name:'bag',
                    color:'grey'
                },
                {
                    name:'zakje',
                    color:'#2F2F2F'
                },
                {
                    name:'umbrella',
                    color:"#dd8e26"
                },
                {
                    name:'bars',
                    color:"#3b3b3b"
                },
                {
                    name:'band',
                    color:"#362000"
                }
            ]
        },
        background:{
            color01:"#FAB89E",
            color02:"#519DF1",
            degrees:20
        }
    },
    {
        name: 'Wutang',
        parts:{
            short:[
                {
                    name: 'cap',
                    color: '#437bb0'
                },
                {
                    name: 'shirt',
                    color: 'white'
                },
                {
                    name: 'pants',
                    color: 'grey'
                },
                {
                    name: 'toe',
                    color: 'black'
                },
                {
                    name: 'mudgard',
                    color: '#F3C443'
                },
                {
                    name: 'heelmudgard',
                    color: '#F3C443'
                },
                {
                    name: 'inside',
                    color: 'black'
                },
                {
                    name: 'outsole',
                    color: '#F3C443'
                },
                {
                    name: 'swoosh',
                    color: '#F3C443'
                },
                {
                    name: 'sides',
                    color: 'black'
                },
                {
                    name: 'heeltop',
                    color: 'black'
                },
                {
                    name: 'heellogo',
                    color: '#F3C443'
                },
                {
                    name: 'thongmid',
                    color: 'black'
                },
                {
                    name: 'midsole',
                    color:'white'
                },
                {
                    name: 'thongouter',
                    color: 'black'
                },
                {
                    name: 'thonglogo',
                    color: '#F3C443'
                },
                {
                    name: 'laces',
                    color: '#F3C443'
                },
                {
                    name: 'thonginner',
                    color: '#F3C443'
                },
                {
                    name: 'ribs',
                    color: '#F3C443'
                },
                {
                    name: 'sock',
                    color: 'white'
                },
                {
                    name: 'sockring',
                    color: 'black' 
                },
                {
                    name:'bag',
                    color:'grey'
                },
                {
                    name:'zakje',
                    color:'#F3C443'
                },
                {
                    name:'umbrella',
                    color:"orange"
                },
                {
                    name:'bars',
                    color:"#3b3b3b"
                },
                {
                    name:'band',
                    color:"#362000"
                }
            ],
            long:[
                {
                    name: 'cap',
                    color: 'black'
                },
                {
                    name: 'shirt',
                    color: '#f3b543'
                },
                {
                    name: 'pants',
                    color: 'black'
                },
                {
                    name: 'toe',
                    color: 'black'
                },
                {
                    name: 'mudgard',
                    color: '#F3C443'
                },
                {
                    name: 'heelmudgard',
                    color: '#F3C443'
                },
                {
                    name: 'inside',
                    color: 'black'
                },
                {
                    name: 'outsole',
                    color: '#F3C443'
                },
                {
                    name: 'swoosh',
                    color: '#F3C443'
                },
                {
                    name: 'sides',
                    color: 'black'
                },
                {
                    name: 'heeltop',
                    color: 'black'
                },
                {
                    name: 'heellogo',
                    color: '#F3C443'
                },
                {
                    name: 'thongmid',
                    color: 'black'
                },
                {
                    name: 'midsole',
                    color:'white'
                },
                {
                    name: 'thongouter',
                    color: 'black'
                },
                {
                    name: 'thonglogo',
                    color: '#F3C443'
                },
                {
                    name: 'laces',
                    color: '#F3C443'
                },
                {
                    name: 'thonginner',
                    color: '#F3C443'
                },
                {
                    name: 'ribs',
                    color: '#F3C443'
                },
                {
                    name: 'sock',
                    color: 'white'
                },
                {
                    name: 'sockring',
                    color: 'black' 
                },
                {
                    name:'bag',
                    color:'grey'
                },
                {
                    name:'zakje',
                    color:'#F3C443'
                },                {
                    name:'umbrella',
                    color:"#847862"
                },
                {
                    name:'bars',
                    color:"#757575"
                },
                {
                    name:'band',
                    color:"black"
                }
            ]
        },
        background:{
            color01:"#FAB89E",
            color02:"#519DF1",
            degrees:20
        }
    },
    {
        name: 'Trav',
        parts:{
            short:[
                {
                    name: 'cap',
                    color: '#437bb0'
                },
                {
                    name: 'shirt',
                    color: 'white'
                },
                {
                    name: 'pants',
                    color: '#253542'
                },
                {
                    name: 'toe',
                    color: 'white'
                },
                {
                    name: 'mudgard',
                    color: 'black'
                },
                {
                    name: 'heelmudgard',
                    color: '#0066cc'
                },
                {
                    name: 'inside',
                    color: '#0066cc'
                },
                {
                    name: 'outsole',
                    color: '#0066cc'
                },
                {
                    name: 'swoosh',
                    color: '#F7F5E8'
                },
                {
                    name: 'sides',
                    color: 'white'
                },
                {
                    name: 'heeltop',
                    color: 'white'
                },
                {
                    name: 'heellogo',
                    color: 'black'
                },
                {
                    name: 'thongmid',
                    color: '#F7F5E8'
                },
                {
                    name: 'midsole',
                    color:'#F7F5E8'
                },
                {
                    name: 'thongouter',
                    color: 'black'
                },
                {
                    name: 'thonglogo',
                    color: '#0066cc'
                },
                {
                    name: 'laces',
                    color: '#ef83a8'
                },
                {
                    name: 'thonginner',
                    color: 'black'
                },
                {
                    name: 'ribs',
                    color: 'black'
                },
                {
                    name: 'sock',
                    color: 'white'
                },
                {
                    name: 'sockring',
                    color: 'grey' 
                },
                {
                    name:'bag',
                    color:'#2F2F2F'
                },
                {
                    name:'zakje',
                    color:'#00e1ff'
                },
                {
                    name:'umbrella',
                    color:"orange"
                },
                {
                    name:'bars',
                    color:"#3b3b3b"
                },
                {
                    name:'band',
                    color:"#362000"
                }
            ],
            long:[
                {
                    name: 'cap',
                    color: '#c26565'
                },
                {
                    name: 'shirt',
                    color: '#2964ac'
                },
                {
                    name: 'pants',
                    color: '#253542'
                },
                {
                    name: 'toe',
                    color: 'white'
                },
                {
                    name: 'mudgard',
                    color: 'black'
                },
                {
                    name: 'heelmudgard',
                    color: '#0066cc'
                },
                {
                    name: 'inside',
                    color: '#0066cc'
                },
                {
                    name: 'outsole',
                    color: '#0066cc'
                },
                {
                    name: 'swoosh',
                    color: '#F7F5E8'
                },
                {
                    name: 'sides',
                    color: 'white'
                },
                {
                    name: 'heeltop',
                    color: 'white'
                },
                {
                    name: 'heellogo',
                    color: 'black'
                },
                {
                    name: 'thongmid',
                    color: '#F7F5E8'
                },
                {
                    name: 'midsole',
                    color:'#F7F5E8'
                },
                {
                    name: 'thongouter',
                    color: 'black'
                },
                {
                    name: 'thonglogo',
                    color: '#0066cc'
                },
                {
                    name: 'laces',
                    color: '#ef83a8'
                },
                {
                    name: 'thonginner',
                    color: 'black'
                },
                {
                    name: 'ribs',
                    color: 'black'
                },
                {
                    name: 'sock',
                    color: 'white'
                },
                {
                    name: 'sockring',
                    color: 'grey' 
                },
                {
                    name:'bag',
                    color:'#17375e'
                },
                {
                    name:'zakje',
                    color:'#00e1ff'
                },
                {
                    name:'umbrella',
                    color:"orange"
                },
                {
                    name:'bars',
                    color:"#3b3b3b"
                },
                {
                    name:'band',
                    color:"#362000"
                }
            ]
        },
        background:{
            color01:"#FAB89E",
            color02:"#519DF1",
            degrees:20
        }
    }
]