import { useLoader} from "@react-three/fiber"
import { useEffect } from "react"
import { useAnimations } from "@react-three/drei"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import AppFunctions from "../AppFunctions.js"

export default function Bag(){
    const app = new AppFunctions()
    const modelGLTF = useLoader(GLTFLoader, app.imports.models.bag, () =>{
        app.loadingManager.done()
    })
    const animations = useAnimations(modelGLTF.animations, modelGLTF.scene)

    useEffect(() =>{
        const action = animations.actions.swing
        action.play()
        
        app.materials.setMaterial(modelGLTF.scene.children[0].children)
    })

    return <primitive scale={1} position={[-0.61,0.558,-0.65]} object={modelGLTF.scene} />
}