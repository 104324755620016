export default {
    early_morning:
        {
            angle: 220,
            colors:[
                {
                    color: '#0085be',
                    location: -30
                },
                {
                    color:'#df6b4b',
                    location: 90
                },
                {
                    color:'#dfb04b',
                    location: 120
                }
            ]
        },
    morning:
        {
            angle: 280,
            colors:[
                {
                    color: '#0085be',
                    location: -30
                },
                {
                    color:'#df6b4b',
                    location: 90
                },
                {
                    color:'#dfb04b',
                    location: 120
                }
            ]
        },
    early_afternoon:{
        angle: 130,
        colors:[
                {
                    color: '#ffe4c2',
                    location: 0
                },
                {
                    color: '#ffcac2',
                    location: 10
                },
                {
                    color:'#c7cff1',
                    location: 30
                },
                {
                    color:'#94B9D7',
                    location: 70
                }
        ]
    },
    afternoon:{
        angle: 0,
        colors:[
            {
                color: '#438298',
                location: -35
            },
            {
                color:'#ed957d',
                location: 80
            },
            {
                color:'#dfb04b',
                location: 130
            }
        ]
    },
    late_afternoon:{
        angle: 33,
        colors:[
            {
                color: '#438298',
                location: 0
            },
            {
                color:'#df6b4b',
                location: 75
            },
            {
                color:'#dfb04b',
                location: 110
            }
        ]
    },
    evening:{
        angle: 110,
        colors:[
            {
                color: '#555e67',
                location: -10
            },
            {
                color: '#0E6C8E',
                location: 0
            },
            {
                color:'#df6b4b',
                location: 80
            },
            {
                color:'#dfb04b',
                location: 120
            }
        ]
    },
    night:{
        angle: 180,
        colors:[
            {
                color: '#302b32',
                location: 0
            },
            {
                color: '#28222c',
                location: 20
            },
            {
                color:'#47414f',
                location: 120
            }
        ]
    }
}