export default [
    {
    name: 'Chicago',
    parts:
    [
        {
            name: 'cap',
            color: 'grey'
        },
        {
            name: 'shirt',
            color: '#8d8d8d'
        },
        {
            name: 'pants',
            color: '#f44336'
        },
        {
            name: 'toe',
            color: 'white'
        },
        {
            name: 'mudgard',
            color: '#f44336'
        },
        {
            name: 'heelmudgard',
            color: 'red'
        },
        {
            name: 'inside',
            color: 'black'
        },
        {
            name: 'outsole',
            color: 'black'
        },
        {
            name: 'swoosh',
            color: 'black'
        },
        {
            name: 'sides',
            color: 'white'
        },
        {
            name: 'heeltop',
            color: 'black'
        },
        {
            name: 'heellogo',
            color: 'black'
        },
        {
            name: 'thongmid',
            color: 'white'
        },
        {
            name: 'midsole',
            color:'white'
        },
        {
            name: 'thongouter',
            color: 'white'
        },
        {
            name: 'thonglogo',
            color: 'white'
        },
        {
            name: 'thongouter',
            color: 'white'
        },
        {
            name: 'laces',
            color: 'black'
        },
        {
            name: 'thonginner',
            color: 'white'
        },
        {
            name: 'ribs',
            color: '#f44336'
        },
        {
            name: 'sock',
            color: 'white'
        },
        {
            name: 'sockring',
            color: '#f44336' 
        },
        {
            name:'bag',
            color:'grey'
        },
        {
            name:'zakje',
            color:'#2F2F2F'
        },
        {
            name:'umbrella',
            color:"orange"
        },
        {
            name:'bars',
            color:"#3b3b3b"
        },
        {
            name:'band',
            color:"#362000"
        }
    ],
    background:{
        color01:"#FAB89E",
        color02:"#519DF1",
        degrees:20
    }
    },
    {
    name: 'Tiff',
    parts:
    [
        {
            name: 'cap',
            color: '#185E62'
        },
        {
            name: 'shirt',
            color: '#04AAAA'
        },
        {
            name: 'pants',
            color: '#2F2F2F'
        },
        {
            name: 'toe',
            color: '#04AAAA'
        },
        {
            name: 'mudgard',
            color: 'black'
        },
        {
            name: 'heelmudgard',
            color: 'black'
        },
        {
            name: 'inside',
            color: 'black'
        },
        {
            name: 'outsole',
            color: 'black'
        },
        {
            name: 'swoosh',
            color: 'silver'
        },
        {
            name: 'sides',
            color: '#04AAAA'
        },
        {
            name: 'heeltop',
            color: 'cyan'
        },
        {
            name: 'heellogo',
            color: 'black'
        },
        {
            name: 'thongmid',
            color: '#04AAAA'
        },
        {
            name: 'midsole',
            color:'white'
        },
        {
            name: 'thongouter',
            color: 'black'
        },
        {
            name: 'thonglogo',
            color: 'black'
        },
        {
            name: 'laces',
            color: 'black'
        },
        {
            name: 'thonginner',
            color: 'cyan'
        },
        {
            name: 'ribs',
            color: 'black'
        },
        {
            name: 'sock',
            color: 'white'
        },
        {
            name: 'sockring',
            color: '#185E62' 
        },
        {
            name:'bag',
            color:'#242424'
        },
        {
            name:'zakje',
            color:'#04AAAA'
        },
        {
            name:'umbrella',
            color:"orange"
        },
        {
            name:'bars',
            color:"#3b3b3b"
        },
        {
            name:'band',
            color:"#362000"
        }
    ],
    background:{
        color01:"#AFEDBA",
        color02:"#033854",
        degrees:135
    }
    },
    {
    name: 'Cherry',
    parts:
    [
        {
            name: 'cap',
            color: '#ef83a8'
        },
        {
            name: 'shirt',
            color: 'white'
        },
        {
            name: 'pants',
            color: '#472302'
        },
        {
            name: 'toe',
            color: '#ef83a8'
        },
        {
            name: 'mudgard',
            color: '#472302'
        },
        {
            name: 'heelmudgard',
            color: '#472302'
        },
        {
            name: 'inside',
            color: '#472302'
        },
        {
            name: 'outsole',
            color: '#472302'
        },
        {
            name: 'swoosh',
            color: 'white'
        },
        {
            name: 'sides',
            color: '#ef83a8'
        },
        {
            name: 'heeltop',
            color: '#472302'
        },
        {
            name: 'heellogo',
            color: '#472302'
        },
        {
            name: 'thongmid',
            color: '#472302'
        },
        {
            name: 'midsole',
            color:'white'
        },
        {
            name: 'thongouter',
            color: 'black'
        },
        {
            name: 'thonglogo',
            color: 'red'
        },
        {
            name: 'laces',
            color: '#f06292'
        },
        {
            name: 'thonginner',
            color: '#472302'
        },
        {
            name: 'ribs',
            color: '#472302'
        },
        {
            name: 'sock',
            color: 'white'
        },
        {
            name: "sockring",
            color: '#ef83a8'
        },
        {
            name:'bag',
            color:'#414141'
        },
        {
            name:'zakje',
            color:'grey'
        },
        {
            name:'umbrella',
            color:"orange"
        },
        {
            name:'bars',
            color:"#3b3b3b"
        },
        {
            name:'band',
            color:"#362000"
        }
    ],
    background:{
        color01:"#FFC8F1",
        color02:"#A7C2E2",
        degrees:45
    }
    },
    {
    name: 'Pidgeon',
    parts:
    [
        {
            name: 'cap',
            color: '#C35649'
        },
        {
            name: 'shirt',
            color: 'white'
        },
        {
            name: 'pants',
            color: '#777570'
        },
        {
            name: 'shirt',
            color: '#f2f2f2'
        },
        {
            name: 'toe',
            color: '#EEE4DE'
        },
        {
            name: 'mudgard',
            color: '#918783'
        },
        {
            name: 'heelmudgard',
            color: '#918783'
        },
        {
            name: 'inside',
            color: '#C35649'
        },
        {
            name: 'outsole',
            color: '#C35649'
        },
        {
            name: 'swoosh',
            color: 'white'
        },
        {
            name: 'sides',
            color: '#EEE4DE'
        },
        {
            name: 'heeltop',
            color: '#EEE4DE'
        },
        {
            name: 'heellogo',
            color: 'white'
        },
        {
            name: 'thongmid',
            color: '#918783'
        },
        {
            name: 'midsole',
            color:'#777570'
        },
        {
            name: 'thongouter',
            color: '#777570'
        },
        {
            name: 'thonglogo',
            color: 'white'
        },
        {
            name: 'laces',
            color: 'white'
        },
        {
            name: 'thonginner',
            color: '#918783'
        },
        {
            name: 'ribs',
            color: '#918783'
        },
        {
            name: 'sock',
            color: 'white'
        },
        {
            name: 'sockring',
            color: '#C35649' 
        },
        {
            name:'bag',
            color:'grey'
        },
        {
            name:'zakje',
            color:'#2F2F2F'
        },
        {
            name:'umbrella',
            color:"#C35649"
        },
        {
            name:'bars',
            color:"#3b3b3b"
        },
        {
            name:'band',
            color:"#362000"
        }
    ],
    background:{
        color01:"#C06C84",
        color02:"#3B598C",
        degrees:232
    }
    },
    {
    name: 'Wutang',
    parts:
    [
        {
            name: 'cap',
            color: '#F3C443'
        },
        {
            name: 'shirt',
            color: '#F3C443'
        },
        {
            name: 'pants',
            color: 'black'
        },
        {
            name: 'toe',
            color: 'black'
        },
        {
            name: 'mudgard',
            color: '#F3C443'
        },
        {
            name: 'heelmudgard',
            color: '#F3C443'
        },
        {
            name: 'inside',
            color: 'black'
        },
        {
            name: 'outsole',
            color: '#F3C443'
        },
        {
            name: 'swoosh',
            color: '#F3C443'
        },
        {
            name: 'sides',
            color: 'black'
        },
        {
            name: 'heeltop',
            color: 'black'
        },
        {
            name: 'heellogo',
            color: '#F3C443'
        },
        {
            name: 'thongmid',
            color: 'black'
        },
        {
            name: 'midsole',
            color:'white'
        },
        {
            name: 'thongouter',
            color: 'black'
        },
        {
            name: 'thonglogo',
            color: '#F3C443'
        },
        {
            name: 'laces',
            color: '#F3C443'
        },
        {
            name: 'thonginner',
            color: '#F3C443'
        },
        {
            name: 'ribs',
            color: '#F3C443'
        },
        {
            name: 'sock',
            color: 'white'
        },
        {
            name: 'sockring',
            color: 'black' 
        },
        {
            name:'bag',
            color:'grey'
        },
        {
            name:'zakje',
            color:'#F3C443'
        }
    ],
    background:{
        color01:"#FFD4B0",
        color02:"#f05053",
        degrees:142
    }
    },
    {
    name: 'Travis',
    parts:
    [
        {
            name: 'cap',
            color: '#437bb0'
        },
        {
            name: 'shirt',
            color: '#ac2929'
        },
        {
            name: 'pants',
            color: '#253542'
        },
        {
            name: 'toe',
            color: 'white'
        },
        {
            name: 'mudgard',
            color: 'black'
        },
        {
            name: 'heelmudgard',
            color: '#0066cc'
        },
        {
            name: 'inside',
            color: '#0066cc'
        },
        {
            name: 'outsole',
            color: '#0066cc'
        },
        {
            name: 'swoosh',
            color: '#F7F5E8'
        },
        {
            name: 'sides',
            color: 'white'
        },
        {
            name: 'heeltop',
            color: 'white'
        },
        {
            name: 'heellogo',
            color: 'black'
        },
        {
            name: 'thongmid',
            color: '#F7F5E8'
        },
        {
            name: 'midsole',
            color:'#F7F5E8'
        },
        {
            name: 'thongouter',
            color: 'black'
        },
        {
            name: 'thonglogo',
            color: '#0066cc'
        },
        {
            name: 'laces',
            color: '#ef83a8'
        },
        {
            name: 'thonginner',
            color: 'black'
        },
        {
            name: 'ribs',
            color: 'black'
        },
        {
            name: 'sock',
            color: 'white'
        },
        {
            name: 'sockring',
            color: 'grey' 
        },
        {
            name:'bag',
            color:'#2F2F2F'
        },
        {
            name:'zakje',
            color:'#00e1ff'
        },
        {
            name:'umbrella',
            color:"orange"
        },
        {
            name:'bars',
            color:"#3b3b3b"
        },
        {
            name:'band',
            color:"#362000"
        }
    ],
    background:{
        color01:"#c34848",
        color02:"#ffeea3",
        degrees:-45
    }
    // background:{
    //     color01:"#CCD6F9",
    //     color02:"#7276D7    ",
    //     degrees:45
    // },
    }
]