import { track } from '@vercel/analytics';
import './Overlay.css'
import {useState, useContext, useEffect } from 'react';
import projectsJson from '../projectData.json'
import sideProjectsJson from '../sideProjectData.json'
import specialProject from '../specialProject.json'
import ImageCarousel from "./ImageCarousel.jsx";
import { projectContext } from '../App.jsx';
import Image from './Image.jsx';
import AppFunctions from '../AppFunctions.js';
import Markdown from 'react-markdown'
export default function Overlay(props) {

    const app = new AppFunctions()

    let [projectObject,setProjectObject] = useContext(projectContext)
    let [sideProject, setSideProject] = useState(sideProjectsJson)

    let projectID = null
    let isOverlayOpen = props.trigger

    useEffect(() =>{
        if(isOverlayOpen){
            const keyPressHandler = (event) =>{
                switch(event.key) {
                    case "ArrowDown":
                    case "Escape":
                        props.closeOverlay('Keyboard-key')
                        break
                }
            }
            window.addEventListener('keyup',keyPressHandler)
            return () => {
                window.removeEventListener('keyup',keyPressHandler)
            }
        }
    },[isOverlayOpen])

    const setProjectData = () =>{
        if(!projectObject.sideProjectBool){
            projectID = projectObject.mainProjectID
            return projectsJson
        }else{
            projectID = projectObject.sideProjectID
            return sideProject
        }
    }

    app.iSeeYou.on('special', () =>{
        setSideProject([...sideProjectsJson,...specialProject])
    })

    let projectData = setProjectData()
    let name = null
    let projectIcon = null
    let placeholderBackground = null
    let description = []
    let links = []
    let images = null

    const parseData = (id,project) => {

        let data = project[id]
        name = data.name
        projectIcon = data.project_icon
        placeholderBackground = data.background


        const parseDescription = (description) => {
            let paragraphBundle = []
            for(let child of description){
                let paragraph = <Markdown key={child.id}>{child.paragraph}</Markdown>                
                paragraphBundle.push(paragraph)
            }
            return paragraphBundle
        }   

        const parseLinks = (links) =>{
            if(links){
                let linkBundle = []
                for(let child of links){
                    let link = <div className='linkContainer' key={child.id}>
                                    <div style={{width:'12px', height:'12px'}}>
                                        <Image imageUrl={child.icon} />
                                    </div>
                                    <a target='_blank' key={child.id} href={child.url}>{child.name}</a>
                                </div>
                    linkBundle.push(link)
                }
                return linkBundle
            }
        }
        
        links = parseLinks(data.links)
        description = parseDescription(data.description)
        images = data.images
    }

    let setProjectIcon = () =>{
        if(projectIcon){
            return <div className='projectIcon'><Image imageUrl={projectIcon} /></div>
        }else{
            return null
        }
    }

    parseData(projectID,projectData)

    return (isOverlayOpen) ? (
        <div className="overlay noselect">
            <div className='overlay_background' onClick={() => props.closeOverlay('backgroundClick')}/>
            <div className={projectObject.sideProjectBool ? 'project_container additional_top' : 'project_container'}>
                <div className="overlay_container">
                    <div className="content">
                        <div className="side">
                            <div className="projectDetails">
                                <div className='projectTitleBar'>
                                    {setProjectIcon()}
                                    <h1>{name}</h1>
                                </div>
                                <div className="projectContent">
                                    {description}
                                </div>
                                <div className="projectFooter">{links}</div>
                            </div>
                        </div>
                        <div className="side">
                            <ImageCarousel trigger={isOverlayOpen} images={images} />
                        </div>
                    </div>
                </div>
                <div className='overlay_border' />
                {(projectObject.sideProjectBool) ? (
                <div className='personal_projects'>
                    {sideProject.map((project,index) =>{
                        return <div className={projectObject.sideProjectID === index ? 'side_project open' : 'side_project '} key={index} 
                            onClick={() => {setProjectObject({sideProjectBool:true,mainProjectID:undefined,sideProjectID:index,update:true}),track('PersonalProject',{SideProjectID:index})}}>
                                <Image alt={project.thumbnail.alt} imageUrl={project.thumbnail.url} />
                            </div>
                    })} 
                </div>):null}
            </div>
        </div>
    ) : null
}