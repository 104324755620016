import Colorways from "../Materials/NewColorways.js";

export default class GenerateColorCode {
  constructor() {
    this.sendNumber = 0;
    this.numbers = [];
    this.colorwayAmount = Colorways.length;

    if (this.colorwayAmount === 0) {
      throw new Error("No colorways available");
    }

    // Initialize the array of numbers
    this.createArray = () => {
      this.numbers = [];
      for (let i = 0; i < this.colorwayAmount; i++) {
        this.numbers.push(i);
      }
    };

    // Shuffle the numbers array
    this.shuffle = (array) => {
      let currentIndex = array.length,
        randomIndex;

      while (currentIndex > 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }
    };

    // Create and return a color code
    this.createCode = () => {
      return new Promise((resolve, reject) => {
        try {
          // Ensure the array is not empty
          if (this.numbers.length === 0) {
            this.createArray();
            this.shuffle(this.numbers);
          }

          // Use numbers from the shuffled array
          if (this.sendNumber < this.colorwayAmount) {
            let number = this.numbers[this.sendNumber];
            this.sendNumber++;
            resolve(number); // Resolve with the number
          } else {
            // Reset the numbers and reshuffle when reaching the limit
            this.sendNumber = 0;
            this.createArray();
            this.shuffle(this.numbers);

            // Return the first number after reshuffling
            resolve(this.numbers[this.sendNumber]);
          }
        } catch (error) {
          reject(error); // Catch any errors
        }
      });
    };

    this.cachedNumber = {
      data: null,
      promise: null,
    };

    // Cache the code to avoid recalculating unnecessarily
    this.getCachedCode = () => {
      if (this.cachedNumber.data !== null) {
        return Promise.resolve(this.cachedNumber.data); // Return cached data if available
      }
      if (this.cachedNumber.promise !== null) {
        return this.cachedNumber.promise; // Return cached promise if still resolving
      }

      this.cachedNumber.promise = this.createCode()
        .then((data) => {
          this.cachedNumber.data = data;
          return data;
        })
        .finally(() => {
          this.cachedNumber.promise = null; // Clear the cached promise
        });

      return this.cachedNumber.promise;
    };

    // Initialize the numbers and shuffle them on construction
    this.createArray();
    this.shuffle(this.numbers);
  }

  // The requestCode method now guarantees a number is returned
  async requestCode(fixed) {
    if (fixed === undefined) {
      const code = await this.getCachedCode();
      return code; // Return the generated code
    } else {
      return fixed; // Return the fixed number if provided
    }
  }
}