import './Contact.css'
import { track } from '@vercel/analytics';

export default function Contact(){
    return <>
        <div className='contact_container'>
            <a onClick={() => track('mail-button')} href='mailto:timothy.velberg@gmail.com?subject=🥕' ><img className='contact_social noselect' src="./icons/mail.svg"/></a>
            <a onClick={() => track ('instagram-button')} href='https://www.instagram.com/timo_cj/' target='_blank'><img className='contact_social noselect' src="./icons/instagram.svg"/></a>
            <a onClick={() => track('dribble-button')}href='https://dribbble.com/Timo_cj' target='_blank'><img className='contact_social noselect' src="./icons/dribbble.svg"/></a>
            <a onClick={() => track('linkedin-button')}href='https://www.linkedin.com/in/timothy-velberg-59633321/' target='_blank'><img className='contact_social noselect' src="./icons/linkedin.svg"/></a>
        </div>
    </>
}